import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useEffect, useState } from "react";
import DietaService from "../../services/DietaService";
import { Button } from "@nextui-org/react";

const TablaSuplementos = ({ handleChangeAccio }) => {

    const [suplementos, setSuplementos] = useState([]);

    useEffect(() => {
        getSuplementos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSuplementos = async () => {
        const data = await DietaService.getSuplmentos();
        setSuplementos(data);

    }

    return (
        <div className="h-full w-full pl-4 pr-4 ">
            {suplementos.length === 0 ? (
                 <p className="text-xs text-default-500 mb-1 ml-2">No hay Suplementos</p>
            ) : (
                <>
                <p className="text-xs text-default-500 mb-1 ml-2">Suplementos</p>
                    <Table className="mb-4">
                        <TableHeader>
                            <TableColumn>Nombre</TableColumn>
                            <TableColumn>Enlace</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {suplementos.map((suplemento) => (
                                <TableRow key={suplemento.id} >
                                    <TableCell

                                        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                    >
                                        {suplemento.nombre}
                                    </TableCell>
                                    <TableCell>
                                        <a href={suplemento.url} target="_blank" rel="noreferrer">
                                            <Button href={suplemento.url} target="_blank" className="text-xs">Ver producto</Button>
                                        </a>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    )
}
export default TablaSuplementos;