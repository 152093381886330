import { Card, CardBody } from '@nextui-org/react';


const FormularioInicialUser = ({ data }) => {

   
    return (
        <Card className="w-full px-9 py-4">

            <CardBody>
            {data && (
                <>
                <p className="text-xs font-semibold text-gray-500">Información del usuario</p>
                <div className='mt-2 grid grid-cols-1 md:grid-cols-4 gap-2'>
                    <p className='text-sm font-light mb-1'>Alergias: <span className='text-gray-500 ml-1'>{data.alergias}</span></p>
                    <p className='text-sm font-light mb-1'>Alimentación: <span className='text-gray-500 ml-1'>{data.alimentacion}</span></p>
                    <p className='text-sm font-light mb-1'>Altura: <span className='text-gray-500 ml-1'>{data.altura}</span></p>
                    <p className='text-sm font-light mb-1'>Ombligo: <span className='text-gray-500 ml-1'>{data.cadera}</span></p>
                    <p className='text-sm font-light mb-1'>Cardiovascular: <span className='text-gray-500 ml-1'>{data.cardiovascular}</span></p>
                    <p className='text-sm font-light mb-1'>Colesterol: <span className='text-gray-500 ml-1'>{data.colesterol}</span></p>
                    <p className='text-sm font-light mb-1'>Deporte: <span className='text-gray-500 ml-1'>{data.deporte}</span></p>
                    <p className='text-sm font-light mb-1'>Diametro Cuello: <span className='text-gray-500 ml-1'>{data.diametro_cuello}</span></p>
                    <p className='text-sm font-light mb-1'>Dias Entrenamiento: <span className='text-gray-500 ml-1'>{data.dias_entrenamiento}</span></p>
                    <p className='text-sm font-light mb-1'>Edad: <span className='text-gray-500 ml-1'>{data.edad}</span></p>
                    <p className='text-sm font-light mb-1'>Enfermedad: <span className='text-gray-500 ml-1'>{data.enfermedad}</span></p>
                    <p className='text-sm font-light mb-1'>Fecha: <span className='text-gray-500 ml-1'>{data.fecha}</span></p>
                    <p className='text-sm font-light mb-1'>Hambre: <span className='text-gray-500 ml-1'>{data.hambre}</span></p>
                    <p className='text-sm font-light mb-1'>Lesiones: <span className='text-gray-500 ml-1'>{data.lesiones}</span></p>
                    <p className='text-sm font-light mb-1'>Objetivo: <span className='text-gray-500 ml-1'>{data.objetivo}</span></p>
                    <p className='text-sm font-light mb-1'>Peso: <span className='text-gray-500 ml-1'>{data.peso}</span></p>
                    <p className='text-sm font-light mb-1'>Profesión: <span className='text-gray-500 ml-1'>{data.profesion}</span></p>
                    <p className='text-sm font-light mb-1'>Sueño: <span className='text-gray-500 ml-1'>{data.sueno}</span></p>
                </div>
                </>
            )}
            {!data && <p className="text-center ">El usuario no ha completado el formulario inicial</p>}
            </CardBody>

        </Card>
    )
}
export default FormularioInicialUser