import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Image, Input, Button } from "@nextui-org/react";
import AuthService from "../services/AuthService";
import React, { useState, useEffect } from 'react';
import { useMessageContext } from '../contexts/MessageContext';
import { useAuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { EyeFilledIcon } from "../components/login/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../components/login/EyeSlashFilledIcons";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";



const LoginForm = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [resetPassword, setResetPassword] = useState(null);
    const { error, success, handleError,handleSuccess, clearMessages } = useMessageContext();
    const { login, isAuthenticated } = useAuthContext();
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            clearMessages();
        }
        if (success) {
            clearMessages();
        }

    }, [error, success, clearMessages]);


    useEffect(() => {

        if (isAuthenticated) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);


    const handleLogin = async () => {


        if (username === '' || password === '') {
            handleError('Por favor, introduce tu nombre de usuario y contraseña.');
            return;
        }

        try {
            const userData = await AuthService.login(username, password);
            login(userData.token, userData.admin, userData.firstTime);

        } catch (error) {
            // Manejar el error de autenticación
            console.log(error);
            handleError(error.message);
            console.error('Error de inicio de sesión:', error.message);
        }

    };

    const handleResetPassword = async () => {
        if (resetPassword === null || resetPassword === '') {
            handleError('Por favor, introduce tu email, nombre de usuario o teléfono para recuperar tu contraseña.');
            return;
        }

        
        try {
            const response = await AuthService.resetPassword(resetPassword);
            console.log(response);
         handleSuccess(response.message);
        } catch (error) {
            console.error('Error al enviar el enlace para restablecer la contraseña:', error.message);
            handleError(error.message);
        }
    }

    return (
        <div>
            <Card className="max-w-[400px]" >
                <CardHeader className="flex gap-3">
                    <Image
                        alt="nextui logo"
                        height={40}
                        radius="sm"
                        src={logo}
                        width={40}
                    />
                    <div className="flex flex-col">
                        <p className="text-md">+40 Coaching</p>
                        <p className="text-small text-default-500">app.40pluscoaching.es</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody className="flex" >
                    <Input className="mb-3" label="Nombre de usuario" placeholder="Introduce tu nombre de usuario" onChange={(e) => setUsername(e.target.value)} value={username} />
                    <Input
                        label="Password"
                        placeholder="Tu contraseña"
                        onChange={(e) => setPassword(e.target.value)} value={password}
                        endContent={
                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                {isVisible ? (
                                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                ) : (
                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                )}
                            </button>
                        }
                        type={isVisible ? "text" : "password"}
                        className="mb-6"
                    />
                    <Button color="primary" onClick={handleLogin}>Iniciar sesión</Button>
                </CardBody>
                <Divider />
                <CardFooter>
                    <Link
                        isExternal
                        showAnchorIcon
                        className="text-sm cursor-pointer"
                        onPress={onOpen}

                    >
                        Tienes problemas para iniciar sesión?
                    </Link>
                </CardFooter>
            </Card>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Recuperar tu contraseña</ModalHeader>
                            <ModalBody> 
                                <p className="text-sm pl1 pr-1">Introduce tu email, nombre de usuario o teléfono para recuperar tu contraseña.</p>
                                <Input className="" label="Introduce tus datos" placeholder="Email, nombre de usuario o teléfono" 
                                onChange={(e) => setResetPassword(e.target.value)} value={resetPassword}
                                />
                                <p className="text-default-500 text-xs pl-1 pr-1">Enviaremos un enlace para restablecer la contraseña al email asociado a la cuenta.</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cerrar
                                </Button>
                                <Button color="primary" onPress={handleResetPassword}>
                                    Enviar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

export default LoginForm;